import React from "react";
import img1 from "../../../assets/img/txsa0oe4ipxcl8u7hp03.png";
import img2 from "../../../assets/img/szjlzi5wganhnh5jqccg.png";
import img3 from "../../../assets/img/bptmywb0nqzsdqvqbdid.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotifyItem = () => {
  const { t } = useTranslation();
  const data = [
    {
      id: "1",
      title: `${t("10% off on Nuts & Spices During Ramadan")}`,
      desc: `${t("10% off on Nuts & Spices During Ramadan PARA")}`,
      url: img1,
    },
    {
      id: "2",
      title: `${t("Get 15% off on all Diary Products")}`,
      desc: `${t("Get 15% off on all Diary Products PARA")}`,
      url: img2,
    },
    {
      id: "3",
      title: `${t("10% Off on Our Unique Selection")}`,
      desc: `${t("10% Off on Our Unique Selection PARA")}`,
      url: img3,
    },
  ];

  return (
    <>
      <section className="notifyItems">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>{t("Prominent Foodstuff Trading in UAE")}</h3>
                <p>{t("Prominent Foodstuff Trading in UAE PARA")}</p>
              </div>
            </div>
            {data.map((item) => {
              return (
                <div className="col-md-4" key={item.id}>
                  <div className="notifyCardText1">
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                    <Link to="/">Shop Now </Link>
                    <div className="notifyCard1">
                      <img
                        src={item.url}
                        alt={item.title}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default NotifyItem;
