import React from "react";
import { useTranslation } from "react-i18next";

// import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="aboutSection pt-5 pb-5">
        <div className="container-fluid">
          <h3 className="subtitle">{t("About Us")}</h3>
          {/* <p className="">{data?.about_company}</p> */}
          <p>{t("about-1")}</p>
          <p>
            {t("about1")}
          </p>
          <p>
            {t("about2")}
          </p>
          <p>
            {t("about3")}
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
