import React from "react";
import img1 from "../../../assets/img/service-01.png";
import img2 from "../../../assets/img/serviceList/honey.jpg";
import img3 from "../../../assets/img/serviceList/Indian-Cereals.jpg";
import img4 from "../../../assets/img/service-04.png";
import { useTranslation } from "react-i18next";



const Provide = () => {
  const { t } = useTranslation();

  const data = [
    {
      "id": "service-thumb01",
      "title":  `${t("fresh milk")}`,
      "url": img1,
      "description": `${t("A content farm is a company that employs large numbers.")}`
    },
    {
      "id": "service-thumb02",
      "title": `${t("pure Honey")}`,
      "url": img2,
      "description": `${t("A content farm is a company that employs large numbers.")}`
    },
    {
      "id": "service-thumb03",
      "url": img3,
      "title": `${t("organic product")}`,
      "description": `${t("A content farm is a company that employs large numbers.")}`
    },
    {
      "id": "service-thumb04",
      "url": img4,
      "title": `${t("dairy products")}`,
      "description": `${t("A content farm is a company that employs large numbers.")}`
    }
  ]
  return (
    <>
      <div className="provideSec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="fisherman-content">
                <h3>{t("what we provide")}</h3>
              </div>
            </div>
            {
              data.map((item)=>{
                return <div className="col-md-3">
                  <div className="provideCard">
                    <div className="provideImg">
                      <img src={item.url} alt={item.title} className="img-fluid" />
                    </div>
                    <div className="provideText">
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Provide;
