import React from "react";
import service1 from "../../../assets/img/serviceList/category1.jpeg";
import service2 from "../../../assets/img/serviceList/category2.jpeg";
import service3 from "../../../assets/img/serviceList/category3.jpeg";
// import service4 from "../../../assets/img/rki/network/baby.png";
// import service5 from "../../../assets/img/rki/network/clean-dishes.png";
// import service6 from "../../../assets/img/rki/network/cutlery.png";
import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
import { useTranslation } from "react-i18next";
function ServiceList() {
  const { t } = useTranslation();

  return (
    <>
      <section className="serviceList sectionPD">
        <div className="container-fluid">
          <div className="serviceItemInfo1">
            <div className="serviceItem1">
              <div className="serviceItemIcon1">
                <Link to="/products">
                  <img src={service1} className="img-fluid" alt="Service" />
                </Link>
                <div className="serviceItemText1">
                  <h5>
                    <Link to="/products">
                      {t("01")} <i className="ri-arrow-right-up-fill"></i>
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="serviceItem1">
              <div className="serviceItemIcon1">
                <Link to="/products">
                  <img src={service2} className="img-fluid" alt="Service" />
                </Link>
                <div className="serviceItemText1">
                  <h5>
                    <Link to="/products">
                    {t("02")}<i className="ri-arrow-right-up-fill"></i>
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="serviceItem1">
              <div className="serviceItemIcon1">
              <Link to="/products"><img src={service3} className="img-fluid" alt="Service" /></Link>
                <div className="serviceItemText1">
                  <h5>
                    <Link to="/products">
                    {t("03")}
                      <i className="ri-arrow-right-up-fill"></i>
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

            {/* <div className="serviceItem">
              <div className="serviceItemIcon1">
                <img src={service4} className="img-fluid" alt="Service" />
              </div>
              <div className="serviceItemText1">
                <h5>
                  <Link to="/products">
                    BATH TIMES
                    <i className="ri-arrow-right-up-fill"></i>
                  </Link>
                </h5>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon1">
                <img src={service5} className="img-fluid" alt="Service" />
              </div>
              <div className="serviceItemText1">
                <h5>
                  <Link to="/products">
                    CLEAN - DISHES
                    <i className="ri-arrow-right-up-fill"></i>
                  </Link>
                </h5>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon1">
                <img src={service6} className="img-fluid" alt="Service" />
              </div>
              <div className="serviceItemText1">
                <h5>
                  <Link to="/products">
                    DISHES & FOOD
                    <i className="ri-arrow-right-up-fill"></i>
                  </Link>
                </h5>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
