import React from "react";
import etg from "../../../assets/img/brands/client1.PNG";
import kynoch from "../../../assets/img/brands/client2.PNG";
import falcon from "../../../assets/img/brands/client3.png";
import oemff from "../../../assets/img/brands/client4.PNG";
import artboard from "../../../assets/img/brands/client5.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OurClient = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="brandsSec">
        <div className="container">
          <div className="brandsHeader">
            <div className="fisherman-content">
              <h3>{t("Our Esteemed Client")}</h3>
            </div>
          </div>
          <div className="brandsItem">
            <ul>
              <li>
                <Link to={"/"}>
                  <img src={etg} alt="Client" title="Client" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={falcon} alt="Client" title="Client" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={kynoch} alt="Client" title="Client" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={oemff} alt="Client" title="Client" />
                </Link>
              </li>

              <li>
                <Link to={"/"}>
                  <img src={artboard} alt="Client" title="Client" />
                </Link>
              </li>
              
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurClient;
