import React, { useEffect, useState } from "react";
import AboutBanner from "./about-banner/AboutBanner";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import AboutStory from "./about-story/AboutStory";
import AboutChoose from "./about-choose/AboutChoose";
import AboutCounters from "./about-counters/AboutCounters";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { base_url } from "../../server";
import Milestone from "./milestone/Milestone";

import ceo1 from "../../assets/img/ceo1.png";
import ceo2 from "../../assets/img/ceo2.PNG";

function About() {
  const [data, setData] = useState();
  const baseUrl = base_url();
  const aboutData = async () => {
    try {
      const res = await axios.get(`${baseUrl}adminWeb_footer/get`);
      setData(res.data);
    } catch (error) {
      console.log("server-error");
    }
  };
  useEffect(() => {
    aboutData();
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <>
      <Breadcrumb title="About Us" t={t} />
      <main className="main about">
        {/* <AboutBanner /> */}
        <AboutStory data={data} t={t} />
        <div className="aboutCom">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <div className="comFigure">
                  <img src={ceo1} alt="Smita Kale, CEO" className="img-fluid" />
                  <h4>{t("Smita Kale, CEO")}</h4>
                  
                </div>
                <div className="comContent p-0">
                    <div className="our w-100">
                      <h5>{t("Our Vision")}</h5>
                      <p>
                      {t("To be the benchmark of FMCG distribution excellence in the mena region")}
                        
                      </p>
                    </div>
                  </div>
              </div>
              <div className="col-md-8">
                <div className="comContent">
                  <h3>{t("Message from The CEO")}</h3>
                  <p>
                    {t("messageCEO1")}
                  </p>
                  <p>
                    {t("messageCEO2")}
                  </p>
                </div>
              </div>
              <div className="mt-5"></div>
              <div className="col-md-4">
                <div className="comFigure">
                  <img
                    src={ceo2}
                    alt="Shrikant Kale, Managing Director"
                    className="img-fluid"
                  />
                  <h4>{t("Shrikant Kale, Managing Director")}</h4>
                </div>
              </div>
              <div className="col-md-8">
                <div className="comContent">
                  <h3>{t("Shrikant Kale, Managing Director")}</h3>
                  <p>
                    {t("ShrikantMSG")}
                  </p>

                  <div className="our">
                    <h5>{t("Our Mission")}</h5>
                    <p>
                      {t("ShrikantMSG1")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AboutChoose t={t} />
        <Milestone t={t} />
        {/* <AboutCounters t={t} /> */}
      </main>
    </>
  );
}

export default About;
